.button {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: #0088ff;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  color: white;
}
.button:hover {
  transition: 0.3s;
  background-color: #1f77c4;
}
.page {
  font-size: 36px;
  text-align: center;
}
.error {
  font-size: 26px;
  color: red;
}
.headings {
  font-size: 160px;
  padding-bottom: 20px;
  text-align: center;
}
.buttondiv {
  text-align: center;
}
