.heading {
  /*notificatin heading*/
  text-align: center;
}
.log {
  padding-top: 10px;
  text-align: center;
  margin-top: 5%;
}
.date {
  margin-left: 20px;
  margin-top: 30px;
}
.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.buttonGroup2 {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.release_note {
  display: flex;
}

.release_note_date {
  width: 100px;
}
.noLog,
.noAlarms {
  text-align: center;
}
.box_container {
  position: relative;
  padding-bottom: 30px;
  margin-left: 15%;
  margin-right: 15%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 3%;
}
@media (max-width: 768px) {
  .box_container {
    margin-left: 5%;
    margin-right: 5%;
  }
}
.box {
  position: relative;
  height: 150px;
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 40%),
    0px 3px 4px 0px rgb(0 0 0 / 24%), 0px 1px 8px 0px rgb(0 0 0 / 22%);
  background-color: #13395a;
  color: #ffffff;
  text-align: center;
}
.notificationButtonGroup {
  padding: 5px 10px;
}
.h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #13395a; /* Changed color for headings */
  font-weight: bold; /* Made headings bold */
  text-shadow: 1px 1px #ffffff; /* Added text shadow for headings */
  font-size: 24px; /* Increased font size for headings */
}
.h2 {
  padding-top: 33px;
  font-size: calc(1em + 0.5vw);
}
.ignoreButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  height: 20px;
  background-color: rgb(248, 94, 94);
  border: none;
  border-radius: 10px; /* half of the button's height */
}
.ignore_all {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}
.ignore_all:hover {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #13395a;
  color: white;
  margin: 10px;
}
.button {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}
