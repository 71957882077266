/* das sind die kästchen */
.data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.input {
  outline: none;
  border: none;
  width: 100%;
  max-width: 400px; /* Add a maximum width for the input fields */
  padding: 15px;
  border-radius: 10px;
  background-color: #edf5f3;
  margin: 5px 0;
  font-size: 14px;
}

.box {
  width: 100%;
  max-width: 300px;
  min-width: 50px; /* Added minimum width */
  height: 180px;
  border-radius: 10px;
  margin: 20px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 40%),
    0px 3px 4px 0px rgb(0 0 0 / 24%), 0px 1px 8px 0px rgb(0 0 0 / 22%);
  background-color: #13395a;
  color: #ffffff;
  text-align: center;
}
.boxbig {
  padding-top: 1px;
  width: 98%;
  height: 500px;
  border-radius: 10px;
  margin: 1%;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 40%),
    0px 3px 4px 0px rgb(0 0 0 / 24%), 0px 1px 8px 0px rgb(0 0 0 / 22%);
  background-color: #13395a;
  color: #ffffff;
  min-height: auto;
  overflow: auto;
}
.h2 {
  text-align: center;
}
p {
  padding-left: 1%;
  padding-right: 1%;
}
.sensordata:hover {
  color: #111;
  background-color: #13395a;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 55%),
    0px 3px 4px 0px rgb(0 0 0 / 39%), 0px 1px 8px 0px rgb(0 0 0 / 37%);
}

.graph {
  color: #111;
  width: auto;
  height: auto;
  padding-right: 200px;
}
/* The button used to open the sidebar */
.heading {
  text-align: center;
}
/* Beginning of the div specifications*/
.blue {
  text-align: center;
}
.loading {
  position: fixed;
  left: 46%;
  top: 50%;
}
.userdata {
  position: fixed;
  left: 20%;
}
/* In your CSS file or a style module (styles.module.css) */
.tableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1%; /* Add 10% padding to the top */
}

.customTable {
  border-collapse: collapse;
  width: 90%; /* Adjust the table width as needed */
}

.customTable th,
.customTable td {
  padding: 8px;
  text-align: center;
}

.customTable th {
  background-color: #ffffff;
  color: black;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.error_msg {
  width: 400px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}
.success_msg {
  width: 400px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  text-align: center;
}
.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.button {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 20px;
  margin-top: 15px;
}
.button:hover {
  color: #2c3e50;
  background-color: #ffffff;
}
