.h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #13395a; /* Changed color for headings */
}
.calendar {
  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-bottom: 5%;
}
.box {
  margin: 5%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
:root {
  --fc-button-bg-color: #13395a;
  --fc-button-border-color: #13395a;
  --fc-button-active-bg-color: #014682;
  --fc-toolbar-title-color: #014682;
  --fc-button-hover-bg-color: #0088ff;
  --fc-button-hover-border-color: #0088ff;
  --fc-today-bg-color: #0b273e68;
}

.space {
  padding: 2rem;
}
