.h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #13395a; /* Changed color for headings */
  font-weight: bold; /* Made headings bold */
  text-shadow: 1px 1px #ffffff; /* Added text shadow for headings */
  font-size: 24px; /* Increased font size for headings */
}
.box {
  margin: 2% auto;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0, 0, 0, 0.4), 0px 3px 4px rgb(0, 0, 0, 0.24),
    0px 1px 8px rgb(0, 0, 0, 0.22);
  background-color: #ffffff;
  color: #13395a;
  padding: 20px;
  max-width: 700px;
}

.box_gray {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0, 0, 0, 0.4), 0px 3px 4px rgb(0, 0, 0, 0.24),
    0px 1px 8px rgb(0, 0, 0, 0.22);
  background-color: #13395a;
  color: #ffffff;
  padding: 20px;
}
.button {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}
.button:hover {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #13395a;
  color: white;
  margin: 10px;
}
.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.data {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 1200px) {
  .data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* Boxen sind untereinander, wenn der Viewport 1250px oder weniger breit ist */
  }
  .box {
    margin: 7% auto;
  }
}
.sensordata {
  width: 100%;
  max-width: 150px;
  height: 140px;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 40%),
    0px 3px 4px 0px rgb(0 0 0 / 24%), 0px 1px 8px 0px rgb(0 0 0 / 22%);
  background-color: #13395a;
  color: #ffffff;
  text-align: center;

  /* Added transition for smooth changes */
  transition: all 0.2s ease-in-out;
}
.sensordata:hover {
  color: #ffffff; /* Changed hover color for text */
  background-color: #13395a;
  box-shadow:
    /* Added box shadow on hover */ 0px 3px 3px -2px rgb(0, 0, 0, 0.55),
    0px 3px 4px rgb(0, 0, 0, 0.39), 0px 1px 8px rgb(0, 0, 0, 0.37);
  transform:
    /* Added scale effect on hover */ scale(1.05);
}
.sensordata p,
.sensordata h2 {
  text-align: center;
}
.elementssensordata {
  position: relative;
  width: 90%;
  margin: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sensorheading {
  color: white;
  margin-top: 0;
}
.sensorvalue {
  margin-bottom: 0;
  font-weight: bold;
}
.sensorvalue.on {
  color: green;
}
.sensorvalue.off {
  color: red;
}
.settingsPopupContent {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0, 0, 0, 0.4), 0px 3px 4px rgb(0, 0, 0, 0.24),
    0px 1px 8px rgb(0, 0, 0, 0.22);
  background-color: #ffffff;
  color: #13395a;
}
.automationsHeading {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #13395a;
}

.title {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #13395a;
  font-weight: bold;
  text-shadow: 1px 1px #ffffff;
  font-size: 24px;
}

.close {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}

.subtitle {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #13395a;
  font-weight: bold;
  text-shadow: 1px 1px #ffffff;
  font-size: 20px;
}

.automation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% auto;
  padding: 20px;
  max-width: 6 00px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0, 0, 0, 0.4), 0px 3px 4px rgb(0, 0, 0, 0.24),
    0px 1px 8px rgb(0, 0, 0, 0.22);
  background-color: #ffffff;
  color: #13395a;
}

.select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #13395a;
}

.input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #13395a;
}
.textContainer,
.inputContainer {
  margin-bottom: 20px;
}
.button {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}

.addButton {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}

.saveButton {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
}
.text {
  margin-left: 5px;
  margin-right: 5px;
}
