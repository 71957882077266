.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 150px; /* Increase the height of the banner */
  background-color: rgba(245, 245, 245, 0.9); /* Semi-transparent background */
  padding: 20px 0;
  text-align: center; /* Center the text */
  border-top: 1px solid #ccc;
  z-index: 1000; /* High z-index to overlay other content */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cookie-banner p {
  margin: 0;
  padding: 0 20px;
  color: #333;
  font-size: 16px;
}

.cookie-banner button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
