.hero_section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #0c2340;
}

.white_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 100px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  margin-right: 20px;
  color: black;
  position: absolute;
  top: 45px;
  right: 20px;
  z-index: 2;
}
.white_btn:hover {
  color: #fff;
  background-color: #0088ff;
}
.shopButton,
.productButton,
.contactButton {
  padding: 12px 0;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin-right: 20px;
  color: white;
  position: absolute;
  top: 40px;
  z-index: 2;
  text-decoration: none;
}
.shopButton:hover,
.productButton:hover,
.contactButton:hover {
  color: #0088ff;
}
.square {
  height: 120px;
}

.logo_and_heading {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}

.logo {
  width: 70px;
  height: auto;
  margin-right: 20px;
}

.pages {
  width: 400px;
  height: auto;
  margin-right: 20px;
}

.project_name {
  color: white;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-family: Orbitron;
}

.info3words {
  color: white;
  font-size: clamp(3.5rem, 4vw, 4.5rem);
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-family: Orbitron;
}

.menuButton {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  background-color: #0c2340;
  color: #fff;
  border: none;
  padding: 10px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100vh;
  background-color: #0c2340;
  z-index: 2;
  transition: 0.3s;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.sidebar.open {
  width: 100%;
  padding: 20px;
}

.sidebar div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 900px) {
  .logo_and_heading {
    align-items: center;
    justify-content: center;
    top: 10px;
  }

  .logo {
    width: 50px;
    margin-right: 10px;
  }

  .project_name {
    font-size: 21px;
    margin-top: 12px;
  }

  .info3words {
    font-size: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .white_btn,
  .shopButton,
  .productButton,
  .contactButton {
    display: none;
  }

  .menuButton {
    display: block;
  }

  .sidebar div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .square {
    height: 70px;
  }
  .white_btn.open,
  .shopButton.open,
  .productButton.open,
  .contactButton.open {
    display: block;
    z-index: 10;
    left: 45%;
    transform: translateX(-50%);
  }

  .productButton.open {
    top: 40%;
    transform: translateY(-50%);
  }
  .shopButton.open {
    top: 45%;
    transform: translateY(-50%);
  }
  .contactButton.open {
    top: 50%;
    transform: translateY(-50%);
  }
  .white_btn.open {
    top: 57%;
    transform: translateY(-50%);
  }
}

@media (min-width: 900px) {
  .white_btn,
  .shopButton,
  .productButton,
  .contactButton {
    display: block;
  }
  .shopButton {
    right: 100px;
  }

  .productButton {
    right: 230px;
  }

  .contactButton {
    right: 360px;
  }
  .menuButton {
    display: none;
  }
  .sidebar {
    display: none;
  }
}

.hero_content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(40%) hue-rotate(240deg) contrast(120%); /* Adjust the brightness and hue-rotate values as needed */
  z-index: 1;
}

.info_section {
  background-color: white; /*#FBDE64*/
  padding: 40px;
  color: white;
  text-align: center;
}
.info_section:nth-child(even) {
  background-color: #0c2340; /* Light gray for odd sections */
}

.info_section:nth-child(odd) {
  background-color: white; /* Darker gray for even sections */
  color: black;
}
.info_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.contact_section {
  padding: 50px 0;
  text-align: center;
  background-color: #f5eec2;
  color: white;
}

.contact_section h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap; /* Allow the items to wrap as necessary */
}

.column {
  flex: 1 0 25%; /* Make the items take up an equal amount of space, but not less than 25% */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .column {
    flex: 1 0 50%; /* On medium screens, items take up half the space */
  }
}

@media (max-width: 600px) {
  .column {
    flex: 1 0 100%; /* On small screens, items take up the full width */
  }
}
.contact_btn {
  border: none;
  outline: none;
  padding: 12px 30px;
  background-color: white;
  border-radius: 20px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  align-self: center;
}

.footer {
  padding: 20px 0;
  text-align: center;
  position: relative;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 2px; /* Adjust the height as desired */
  background-color: #0c2340;
}

.footer a {
  margin-right: 10px;
  color: #ffffff;
}
.custom_list {
  list-style-position: inside;
  padding-left: 20px;
  font-size: 20px;
}
.flex_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .flex_container {
    flex-direction: row;
    justify-content: space-between;
  }
}
.centered_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
}

.centered_list div {
  width: 80%; /* Adjust this value as needed */
}

@media screen and (min-width: 768px) {
  .centered_list {
    width: 45%; /* Adjust this value as needed */
  }
}
h3 {
  margin-top: 30px;
}
p {
  margin: auto;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 750px;
}
@font-face {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/Orbitron/Orbitron-VariableFont_wght.ttf")
    format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
