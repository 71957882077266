.h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #2c3e50; /* Changed color for headings */
  font-weight: bold; /* Made headings bold */
  text-shadow: 1px 1px #ffffff; /* Added text shadow for headings */
  font-size: 24px; /* Increased font size for headings */
}
.data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.elementssensordata {
  position: relative;
  width: 90%;
  margin: 5%;
  display: flex;
  flex-wrap: wrap; /* Added flex-wrap property */
  justify-content: center; /* Center the elements horizontally */
}

.sensordata {
  width: 100%;
  max-width: 150px;
  min-width: 20px; /* Added minimum width */
  height: 140px;
  border-radius: 10px;
  margin: 20px 10px; /* Added some margin between the elements */
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 40%),
    0px 3px 4px 0px rgb(0 0 0 / 24%), 0px 1px 8px 0px rgb(0 0 0 / 22%);
  background-color: #13395a;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.sensordata:hover {
  color: #ffffff;
  background-color: #13395a;
  box-shadow: 0px 3px 3px -2px rgb(0, 0, 0, 0.55),
    0px 3px 4px rgb(0, 0, 0, 0.39), 0px 1px 8px rgb(0, 0, 0, 0.37);
  transform: scale(1.05);
}

.sensordata p,
.sensordata h2 {
  text-align: center;
}
.sensorheading {
  color: white;
  margin-top: 0;
  margin: 30px;
}
.sensorvalue {
  margin-bottom: 0;
  font-weight: bold;
}
