.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #2c3e50; /* Changed color for headings */
}
h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #2c3e50; /* Changed color for headings */
  font-weight: bold; /* Made headings bold */
  text-shadow: 1px 1px #ffffff; /* Added text shadow for headings */
  font-size: 34px; /* Increased font size for headings */
}
.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.diagramm {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}
.chart_container {
  position: relative;
  padding-top: 1%;
  padding-bottom: 1%;
  margin: 1%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 20px;
  border-radius: 10px; /* Add this line */
}
.hint {
  padding: 10px;
  margin-top: 20px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 2%;
  margin-left: 2%;
}
