.diagramm {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
}
@media (max-width: 768px) {
  .diagramm {
    margin-left: 5%;
    margin-right: 5%;
  }
}
.chart_container {
  padding-top: 0.02px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.55);
  border-radius: 10px;
}

.graph {
  color: #111;
  width: 100%;
  height: calc(100% + 20px); /* Adjust the height as desired */
  padding-bottom: 10px;
}

.chart canvas {
  width: 100% !important;
  height: 100% !important;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
.edit_popup {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  font-size: calc(0.4em + 1vw);
  cursor: pointer;
  background-color: #13395a;
  color: white;
  margin: 10px;
}
.edit_popup:hover {
  transition: 0.3s;
  background-color: #0088ff;
}
@media (max-width: 768px) {
  .edit_popup {
    font-size: calc(0.4em + 1vw);
    margin: 5px;
  }
}
@media (min-width: 1130px) {
  .edit_popup {
    font-size: 14px; /* Set the font size to a fixed value */
  }
}
h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #2c3e50; /* Changed color for headings */
  font-weight: bold; /* Made headings bold */
  text-shadow: 1px 1px #ffffff; /* Added text shadow for headings */
  font-size: 34px; /* Increased font size for headings */
}
.heading {
  text-align: center;
  font-family: "Open Sans", sans-serif; /* Changed font for headings */
  color: #2c3e50; /* Changed color for headings */
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.blue {
  text-align: center;
}

.loading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.select {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.checkbox {
  text-align: center;
}
.button-on {
  background-color: #0088ff;
}
