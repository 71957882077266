.navbar {
  background-color: #13395a;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 10;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;

  z-index: 10;
}

.nav-menu {
  background-color: #13395a;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: flex-center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  overflow-y: auto;
  height: 100vh;
}

.nav-menu:after {
  content: "";
  position: absolute;
  top: 0;
  left: 64px;
  width: calc(100% - 64px);
  box-shadow: inset -10px 0px 10px -10px rgba(255, 255, 255, 0.22);
  height: 100%;
}
.nav-menu.active {
  left: -34px;
  transition: 350ms;
  z-index: 10;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  z-index: 11;
}
.icons {
  font-size: 1.4rem;
  margin-right: 20px;
}
.nav-text a:hover {
  background-color: #0088ff; /* sick green #1cc182*/
}

.nav-menu-items {
  width: 100%;
  height: 100vh;
}

.navbar-toggle {
  background-color: #13395a;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: top;
}

span {
  margin-left: 16px;
}
.profile_picture {
  color: #0088ff;
  float: right;
  margin-right: 10px;
}
.footer {
  margin-left: 35px;
  margin-top: 10%;
}
.imprint {
  color: rgb(169, 165, 165);
  margin: 2px;
  margin-bottom: 20px;
}
.button {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  color: black;
  margin: 10px;
}
.button:hover {
  position: relative;
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #0088ff;
  color: white;
  margin: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.placeholder {
  color: #13395a;
}
.line {
  border-bottom: 1px solid #fff;
  margin: 0 40px; /* Adjust margin as needed */
}
