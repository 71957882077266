.buttonContainer {
  justify-content: center;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.delete_button {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #13395a;
  color: white;
  margin: 10px;
  position: fixed;
  right: 0;
  bottom: 0%;
}
.delete_button:hover {
  transition: 0.3s;
  background-color: #0088ff;
}
