.customButton {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  font-size: calc(0.4em + 1vw);
  cursor: pointer;
  background-color: #13395a;
  color: white;
  margin: 10px;
}
@media (max-width: 768px) {
  .customButton {
    width: 80px;
  }
}

.activeButton {
  background-color: #0088ff;
  border: 1.5px solid #0088ff;
}
.box-container {
  border-radius: 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}
@media (max-width: 768px) {
  .box-container {
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 10px;
  }
}
.override-box-color {
  background-color: white;
}
.override_button_color {
  background-color: black;
}
